const newsAccordion = (() => {
  if (
    document.querySelector("body#pageNews.release") !== null ||
    document.querySelector("body#pageNews.information") !== null 
  ) {



    let sW = window.innerWidth;

    let sec = document.querySelector('#jsNewsComSecNews');
    let areas = sec.querySelectorAll('.js-news-area');


    areas.forEach((element, index) => {

      let btn = element.querySelector('.js-btn-box');
      let list = element.querySelector('.js-list-box');

      let listH = list.clientHeight;
      // console.log(listH)
      setTimeout(() => {
        list.style.height = `${0}px`
      }, 300);

      window.addEventListener("resize", (event) => {
        if (btn.classList.contains('act') == true) {
          list.style.height = `auto`
          listH = list.clientHeight;
          list.style.height = `${listH}px`
        }
      });

      btn.addEventListener('click', (event) => {
        if (btn.classList.contains('act') == true) {
          list.style.height = `${0}px`
          btn.classList.remove('act');
        } else {
          list.style.height = `${listH}px`
          btn.classList.add('act');
        }
      })

    });



  }
})()
export default newsAccordion;