const funcNav = (() => {



  if (document.querySelector("nav#jsNav") !== null) {
    if (document.querySelector("button#jsNavBtn") !== null) {



      let sW = window.innerWidth;

      let body = document.querySelector('body');
      let nav = document.getElementById('jsNav');
      let navBtn = document.getElementById('jsNavBtn');

      navBtn.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (navBtn.classList.contains('act') == true) {
          body.classList.remove('hidden');
          nav.classList.remove('act');
          navBtn.classList.remove('act');
        } else {
          body.classList.add('hidden');
          nav.classList.add('act');
          navBtn.classList.add('act');
        }
      });


      if (sW > 750) {

        let lis = nav.querySelectorAll('li.li-p');

        lis.forEach((element, index) => {

          if (index != 4 && index != 5) {

            let childBox = element.querySelector('.child-box');

            gsap.set(childBox, {
              display: 'none',
              opacity: 0,
            })

            element.addEventListener('mouseover', () => {
              gsap.to(childBox, {
                display: 'block',
                opacity: 1,
              })
            });

            element.addEventListener('mouseleave', () => {
              gsap.to(childBox, {
                display: 'none',
                opacity: 0,
              })
            });
          }
        });

      } else {

        let lis = nav.querySelectorAll('li.li-p');

        lis.forEach((element, index) => {

          if (index != 4 && index != 5) {

            let childBox = element.querySelector('.child-box');
            let childBoxH = childBox.clientHeight;

            element.addEventListener('click', () => {
              if (element.classList.contains('act') == true) {
                element.classList.remove('act');
                childBox.style.height = `${0}px`
              } else {
                element.classList.add('act');
                childBox.style.height = `${childBoxH}px`
              }
            });

            childBox.style.height = `${0}px`

          }
        });

      }



    }
  }



})();
export default funcNav;