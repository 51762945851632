const serviceResultsInvestmentTab = (() => {
  if (document.querySelector("body#pageService.results-investment") !== null) {



    let sW = window.innerWidth;

    let sec = document.querySelector('#jsSecInvestment');
    let tabWrap = sec.querySelector('.js-tab-wrap');
    let btn = tabWrap.querySelectorAll('.js-tab-box');

    let investmentWraper = sec.querySelector('.js-investment-wraper');
    let investmentWraperInners = investmentWraper.querySelectorAll('.investment-wraper-inner');




    btn.forEach((element, index) => {

      element.addEventListener('click', () => {

        for (let i = 0; i < btn.length; i++) {
          btn[i].classList.remove('act');
          investmentWraperInners[i].classList.remove('act');
        }
        btn[index].classList.add('act');
        investmentWraperInners[index].classList.add('act');

      })

    });



    btn[0].classList.add('act');
    investmentWraperInners[0].classList.add('act');



  }
})()
export default serviceResultsInvestmentTab;