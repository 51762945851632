const sustainabilityGreenBuildingAccordion = (() => {
  if (document.querySelector("body#pageSustainability.green-building") !== null) {



    let sW = window.innerWidth;

    let sec = document.querySelector('#jsSecLists');
    let wraps = sec.querySelectorAll('.js-list-wrap');


    wraps.forEach((element, index) => {

      let btn = element.querySelector('.js-btn-box');
      let table = element.querySelector('.js-table-box');

      let tableH = table.clientHeight;
      // console.log(tableH)
      setTimeout(() => {
        table.style.height = `${0}px`
      }, 300);

      window.addEventListener("resize", (event) => {
        if (btn.classList.contains('act') == true) {
          table.style.height = `auto`
          tableH = table.clientHeight;
          table.style.height = `${tableH}px`
        }
      });

      btn.addEventListener('click', (event) => {
        if (btn.classList.contains('act') == true) {
          table.style.height = `${0}px`
          btn.classList.remove('act');
        } else {
          table.style.height = `${tableH}px`
          btn.classList.add('act');
        }
      })

    });



  }
})()
export default sustainabilityGreenBuildingAccordion;