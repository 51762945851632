const topLinksAccordion = (() => {
  if (document.querySelector("body#pageTop") !== null) {



    let sW = window.innerWidth;

    let secLinks = document.querySelector('#jsSecLinks');
    let linksWraps = secLinks.querySelectorAll('.js-links-wrap');


    linksWraps.forEach((element, index) => {

      let btn = element.querySelector('.js-btn');
      let link = element.querySelector('.js-links');
      let as = link.querySelectorAll('a');

      let linkH;
      if (sW > 750) {
        linkH = as.length * 6.4;
      } else {
        linkH = as.length * 6.4 * 1.5;
      }

      link.style.height = `${0}rem`

      btn.addEventListener('click', (event) => {
        if (btn.classList.contains('act') == true) {
          link.style.height = `${0}rem`
          btn.classList.remove('act');
        } else {
          link.style.height = `${linkH}rem`
          btn.classList.add('act');
        }
      })

    });



  }
})()
export default topLinksAccordion;