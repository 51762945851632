import { funcScrollControl } from './index';

const funcLoad = () => {



  if (document.querySelector("#jsLoadWrap") !== null) {
    let loadWrap = document.getElementById('jsLoadWrap');
    loadWrap.classList.add('no-act');
    funcScrollControl.returnScroll();

  } else {
    funcScrollControl.returnScroll();

  }


}
export default funcLoad;