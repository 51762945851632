import {
  funcScrollControl,
  funcLoad,
} from './index';

const funcEntry = (() => {


  funcScrollControl.noScroll();

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {

    let secMain = pageTop.querySelector('#jsSecMain');
    let sW = window.innerWidth;
    let video
    if (sW > 750) {
      video = secMain.querySelector('.js-video');
    } else {
      video = secMain.querySelector('.js-video-sp');
    }
    video.load();

    video.addEventListener('loadeddata', () => {
      funcLoad()
      video.play();
    });
    video.addEventListener('canplay', () => {
      funcLoad()
      video.play();
    });

    // document.addEventListener("DOMContentLoaded", () => {
    //   funcLoad()
    // });

  } else {

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
    });

  }


})();
export default funcEntry;