const comNavBreadcrumbs = (() => {
  if (document.querySelector("nav#jsNavBreadcrumbs") !== null) {



    let sW = window.innerWidth;

    let breadcrumbs = document.querySelector('#jsNavBreadcrumbs');


    {
      let header = document.querySelector('header.header');
      let headerH;

      const getHeaderH = () => {
        headerH = header.clientHeight;
        // console.log(headerH)
        return headerH
      }

      let to = gsap.to(breadcrumbs, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: breadcrumbs,
          start: () => `0 ${getHeaderH()}`,
          endTrigger: 'body',
          end: "100% 0%",
          pin: true,
          pinSpacing: false,
          // markers: true,
        },
      })

      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          to.scrollTrigger.refresh()
        });
      });
      observer.observe(target);
    }



  }
})()
export default comNavBreadcrumbs;