const serviceResultsLeaseTab = (() => {
  if (document.querySelector("body#pageService.results-lease") !== null) {



    let sW = window.innerWidth;

    let sec = document.querySelector('#jsSecLease');
    let tabWrap = sec.querySelector('.js-tab-wrap');
    let btnA = tabWrap.querySelectorAll('.js-tab-box-a');
    let btnB = tabWrap.querySelectorAll('.js-tab-box-b');

    let leaseWraper = sec.querySelector('.js-lease-wraper');




    btnA.forEach((element, index) => {

      // a,b = disableタブ c = actからの初期位置
      // const disTab = (a, b, c) => {
      //   if (index == a) {
      //     btnB[b].classList.add('dis');
      //     if (btnB[b].classList.contains('act') == true) {
      //       for (let i = 0; i < btnB.length; i++) {
      //         btnB[i].classList.remove('act');
      //       }
      //       btnB[c].classList.add('act');
      //       leaseWraper.classList.remove(`b-${b}`);
      //       leaseWraper.classList.add(`b-${c}`);
      //     }
      //   }
      // }


      element.addEventListener('click', () => {

        for (let i = 0; i < btnA.length; i++) {
          btnA[i].classList.remove('act');
        }
        element.classList.add('act');

        for (let i = 0; i < btnA.length; i++) {
          leaseWraper.classList.remove(`a-${i}`);
        }
        leaseWraper.classList.add(`a-${index}`);

        for (let i = 0; i < btnB.length; i++) {
          btnB[i].classList.remove('dis');
        }

        // // a-1 b4 dis
        // disTab(1, 4, 0);
        // // a-3 b0 dis
        // disTab(3, 0, 1);

      })

    });


    btnB.forEach((element, index) => {

      element.addEventListener('click', () => {

        for (let i = 0; i < btnB.length; i++) {
          btnB[i].classList.remove('act');
        }
        element.classList.add('act');

        for (let i = 0; i < btnB.length; i++) {
          leaseWraper.classList.remove(`b-${i}`);
        }
        leaseWraper.classList.add(`b-${index}`);

      })

    });



    let leaseWraperInners = leaseWraper.querySelectorAll('.lease-wraper-inner');

    leaseWraperInners.forEach(element => {
      element.style.display = 'none'
    });

    let url = new URL(window.location.href);
    let params = url.searchParams;
    let paramsA = params.get('a')
    let paramsB = params.get('b')
    // console.log(paramsA);
    // console.log(paramsB);
    if (paramsA == 0) {
      btnA[0].classList.add('act');
      leaseWraper.classList.add(`a-${0}`);
    } else if (paramsA == 1) {
      btnA[1].classList.add('act');
      leaseWraper.classList.add(`a-${1}`);
    } else if (paramsA == 2) {
      btnA[2].classList.add('act');
      leaseWraper.classList.add(`a-${2}`);
    } else if (paramsA == 3) {
      btnA[3].classList.add('act');
      leaseWraper.classList.add(`a-${3}`);
    } else {
      btnA[0].classList.add('act');
      leaseWraper.classList.add(`a-${0}`);
    }

    if (paramsB == 0) {
      btnB[0].classList.add('act');
      leaseWraper.classList.add(`b-${0}`);
    } else if (paramsB == 1) {
      btnB[1].classList.add('act');
      leaseWraper.classList.add(`b-${1}`);
    } else if (paramsB == 2) {
      btnB[2].classList.add('act');
      leaseWraper.classList.add(`b-${2}`);
    } else if (paramsB == 3) {
      btnB[3].classList.add('act');
      leaseWraper.classList.add(`b-${3}`);
    } else if (paramsB == 4) {
      btnB[4].classList.add('act');
      leaseWraper.classList.add(`b-${4}`);
    } else {
      btnB[0].classList.add('act');
      leaseWraper.classList.add(`b-${0}`);
    }

    // btnA[0].classList.add('act');
    // btnB[0].classList.add('act');
    // leaseWraper.classList.add(`a-${0}`);
    // leaseWraper.classList.add(`b-${0}`);



    // タブピン
    // {
    //   let header = document.querySelector('header.header');
    //   let headerH;

    //   const getHeaderH = () => {
    //     headerH = header.clientHeight;
    //     // console.log(headerH)
    //     return headerH
    //   }

    //   let to = gsap.to(tabWrap, {
    //     scrollTrigger: {
    //       invalidateOnRefresh: true,
    //       trigger: tabWrap,
    //       start: () => `0 ${getHeaderH()}`,
    //       endTrigger: 'body',
    //       end: "100% 0%",
    //       pin: true,
    //       pinSpacing: false,
    //       // markers: true,
    //     },
    //   })

    //   let target = document.querySelector('body');
    //   let observer = new ResizeObserver((entries) => {
    //     entries.forEach((entry) => {
    //       to.scrollTrigger.refresh()
    //     });
    //   });
    //   observer.observe(target);
    // }



  }
})()
export default serviceResultsLeaseTab;