const funcScrollAnchor = (() => {



  const header = document.querySelector('header.header');

  gsap.utils.toArray('a[href^="#"]').forEach(function (a) {
    a.addEventListener("click", (e) => {
      e.preventDefault();
      gsap.to(window, {
        duration: 1,
        ease: 'power4.out',
        scrollTo: {
          y: a.getAttribute("href"),
          autoKill: false,
          // offsetY: header.offsetHeight,
          offsetY: 0,
        }
      });
    });
  });

  gsap.utils.toArray('a[href^="./#"]').forEach(function (a) {
    a.addEventListener("click", (e) => {
      e.preventDefault();
      let y = a.getAttribute("href").replace("./", "");
      gsap.to(window, {
        duration: 1,
        ease: 'power4.out',
        scrollTo: {
          y: y,
          autoKill: false,
          // offsetY: header.offsetHeight,
          offsetY: 0,
        }
      });
    });
  });



})();
export default funcScrollAnchor;