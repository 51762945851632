const sustainabilitySdgsAccordion = (() => {
  if (document.querySelector("body#pageSustainability.sdgs") !== null) {



    let sW = window.innerWidth;

    let secs = document.querySelectorAll('.js-sec-cntnt');


    secs.forEach((element, index) => {

      let wraps = element.querySelector('.js-copy-wrap');
      if (wraps) {

        let btn = wraps.querySelector('.js-btn-box');
        let table = wraps.querySelector('.js-acc-box');

        if (btn && table) {


          let tableH = table.clientHeight;
          // console.log(tableH)
          setTimeout(() => {
            table.style.height = `${0}px`
          }, 300);

          window.addEventListener("resize", (event) => {
            if (btn.classList.contains('act') == true) {
              table.style.height = `auto`
              tableH = table.clientHeight;
              table.style.height = `${tableH}px`
            }
          });

          btn.addEventListener('click', (event) => {
            if (btn.classList.contains('act') == true) {
              table.style.height = `${0}px`
              btn.classList.remove('act');
            } else {
              table.style.height = `${tableH}px`
              btn.classList.add('act');
            }
          })

        }
      }

    });



  }
})()
export default sustainabilitySdgsAccordion;