const topMain = (() => {
  if (document.querySelector("body#pageTop") !== null) {



    



  }
})()
export default topMain;