const comSwitchCover = (() => {

  const elem = document.querySelectorAll('.js-com-switch-cover');
  if (elem.length > 0) {



    let header = document.querySelector('header.header');
    let headerH = header.clientHeight;
    // console.log(headerH)


    for (let i = 0; i < elem.length; i++) {

      let tl = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: elem[i],
          start: `0% ${headerH - 1}px`,
          onEnter: () => elem[i].classList.add('none'),
          // onLeave: () => console.log("onLeave"),
          // onEnterBack: () => console.log("onEnterBack"),
          onLeaveBack: () => elem[i].classList.remove('none'),
          // markers: true,
        },
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          tl.scrollTrigger.refresh()
        });
      });
      observer.observe(target);

    }



  }

})();
export default comSwitchCover;